import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "contract-order"
};
const _hoisted_2 = {
  class: "content acea-row row-center-wrapper"
};
const _hoisted_3 = ["onClick"];
import { router } from '@/router';
import { computed, ref, watch } from 'vue';
import { contractOrder } from '@/http';
import { useUserStore } from "@/stores/user";
import contOrder from '@/components/contOrder/contOrder.vue';
import { useRoute } from "vue-router";
export default {
  __name: 'contractOrder',
  setup(__props) {
    const total = ref(0);
    const pageSize = ref(5);
    const list = ref([]);
    const page = ref([1, 1]);
    const active = ref(0);
    const loading = ref([false, false]);
    const finished = ref([false, false]);
    const userStore = useUserStore();
    /**  @property{Array} tabList = [加密货币,外汇,金子,期货] */
    const tabList = computed(() => userStore.tabList);
    const route = useRoute();
    const typeTos = route.query.order ? parseInt(route.query.order) : 0;
    let tabActive = ref(typeTos);
    const isContOrder = ref(null);
    const setTabs = index => {
      tabActive.value = index;
      if (isContOrder.value) {
        isContOrder.value.setTabs(index);
      }
    };
    let toBg = ref(false);
    const changes = e => {
      toBg.value = e;
    };
    let show = ref(false);
    const scroll = e => {
      show.value = e.scrollTop > 10;
    };
    const finist = () => {
      page.value = [1, 1];
      _contractOrder();
    };
    const changeList = net => {
      console.log(net);
      page.value[active.value] = net;
      _contractOrder();
    };
    const _contractOrder = () => {
      contractOrder({
        state: active.value,
        page: page.value[active.value],
        perPage: pageSize.value
      }).then(({
        data
      }) => {
        list.value = initList(data.data);
        total.value = data.total;
      });
    };
    const initList = dataList => {
      let to = null;
      if (tabActive.value === 0) {
        dataList.forEach(item => {
          console.log(item['product'].category);
        });
        to = dataList.filter(item => item['product'].category === 0 || item['product'].category === 2);
      } else {
        to = dataList.filter(item => item['product'].category === 3);
      }
      return to;
    };
    const back = () => {
      router.go(-1);
    };
    _contractOrder();
    watch(active, newVal => {
      console.log(newVal);
      _contractOrder();
    });
    return (_ctx, _cache) => {
      const _component_van_sticky = _resolveComponent("van-sticky");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_sticky, {
        onChange: changes
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: _normalizeClass(["home-tab", {
            'homeBgs': _unref(toBg)
          }])
        }, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabList.value, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["items", {
              'active': _unref(tabActive) === item.value
            }]),
            style: {
              "margin-right": "10px"
            },
            onClick: $event => setTabs(item.value),
            key: index
          }, _toDisplayString(_ctx.$t(item.name)), 11, _hoisted_3);
        }), 128))])], 2)]),
        _: 1
      }), _createVNode(contOrder, {
        types: _unref(tabActive),
        ref_key: "isContOrder",
        ref: isContOrder
      }, null, 8, ["types"])]);
    };
  }
};